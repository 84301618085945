import React, { Fragment } from "react";
import "./styles.sass";
import { Link } from "gatsby";

import Image from 'components/common/utils/Image';
import authorMap from 'components/Author/data'

export class Author extends React.Component {
  render() {
    const { data: { name } } = this.props;

    const authorInfo = authorMap[name] || {
      role: "Rédigé par",
      name: 'Charles.co',
      image: 'pp_default.jpeg'
    };

    return (
      <div className="blog-author-block d-flex">
        <div className="blog-author-block-image">
          <Image className="rounded-circle overflow-hidden" style={{ backgroundColor: 'white' }} filename={authorInfo.image} alt={name} />
        </div>
        <div className="blog-author-block-desc">
          <div className="blog-author-block-title">
            {authorInfo.role}
          </div>
          <div className="blog-author-block-name">
            <Link to={authorInfo.pageSlug}>{name}</Link>
          </div>
        </div>
      </div>
    );
  }
}

export class AuthorDescription extends React.Component {
  render() {

    const { name } = this.props;
    const authorInfo = authorMap[name]
    return authorInfo ? (
      <div className="blog-post-author">
        <h3 className="blog-post-author-name">{name}</h3>
        <p className="blog-post-author-description">{authorInfo.description}</p>
      </div>
    ) : null
  }
}
