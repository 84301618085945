import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

import { useStaticQuery, graphql } from "gatsby"

import { useLocation } from "@reach/router"

import config from 'utils/siteConfig';
import authorMap from 'components/Author/data'

const StructuredArticle = ({
  postId
}) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allWpPost {
        nodes {
          id
          title
          uri
          authorId
          modified
          date
          author {
            node {
              name
              firstName
              lastName
              uri
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `)

  const item = data.allWpPost.nodes.find((n) => n.id == postId);

  if (!item) {
    return null;
  }

  const {
    siteUrl,
    siteName,
    logo
  } = config
  const pathname = useLocation().pathname

  let authorBlock;
  let contributorBlock;

  if (item.author && authorMap[item.author.node.name]) {
    authorBlock = {
      "@type": "Person",
      "name": item.author.node.name,
      "url": `${siteUrl}/blog/author${authorMap[item.author.node.name].pageUri}`
    }
    if (item.author.node.name != 'Gilbert Bou Jaoudé')
      contributorBlock = {
        "@type": "Person",
        "name": 'Gilbert Bou Jaoudé',
        "url": `${siteUrl}/blog/author${authorMap['Gilbert Bou Jaoudé'].pageUri}`
      }
  }
  else
    authorBlock = {
      "@type": "Organization",
      "name": siteName
    }

  const schema = {
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${siteUrl}${pathname}`
    },
    "headline": item.title,
    "image": item.featuredImage.node.sourceUrl,
    "datePublished": item.date,
    "dateModified": item.modified,
    "author": authorBlock,
    "contributor": contributorBlock,
    "publisher": {
      "@type": "Organization",
      "name": siteName,
      "logo": {
        "@type": "ImageObject",
        "url": `${siteUrl}${logo}`
      },
    }
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    </>
  );
};

StructuredArticle.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default StructuredArticle;
