import React, { Fragment } from "react";
import { Link } from "gatsby";

export class MiaDescription extends React.Component {
  render() {
    return (
      <div className="blog-description-mia">
        <h3 className="blog-description-mia-title">Mia.co : la plateforme de santé dédiée aux femmes</h3>
        <p>
          Mia.co est une plateforme de santé dédiée aux femme regroupant des praticiennes toutes diplomées en sexologie.
        </p>
        <p>
          Elles sont encadrées par un comité scientifique composé de médecins sexologues et de professeur.e.s d’université en sexologie et de membres dirigeant.e.s d’association de sexologues.
        </p>
        <p>
          La plateforme permet à toutes les femmes de <strong>consulter en ligne</strong> l’une des spécialistes des questions de sexualité et/ou de bien-être.
          La consultation en ligne peut se dérouler <strong>par 3 modes</strong> (téléphone, messagerie sécurisée ou visioconférence) et comporte tous les avantages suivants :
        </p>
        <ul>
          <li>Une équipe de praticiennes <strong>spécialistes de la sexualité féminine</strong> et spécialement formées à la pratique de la téléconsultation ;</li>
          <li>Une consultation de 30 min à <strong>45 euros</strong> (les prix en cabinet varient de 80 à 100 euros) ;</li>
          <li><strong>La livraison de produits</strong> d’accompagnement en 24 ou 48H ;</li>
        </ul>
        <p>
          Les données sont collectées et sécurisées chez un hébergeur agréé et certifié pour les données de santé au sens des dispositions de l’article L.1111-8 du code de la santé publique.
        </p>
      </div>
    );
  }
}
