import React from "react";
import Helmet from "react-helmet";
import CharlesMeta from "components/common/meta/CharlesMeta";
import ShareButtons from "components/common/Share";
import ScrollCta from "components/common/ScrollCta";
import { Link, graphql } from "gatsby";
import BlogLayout from "components/common/blog/BlogLayout";
import StructuredArticle from "components/common/meta/StructuredArticle";
import StructuredFAQ from "components/common/meta/StructuredFAQ";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Blocks from "templates/Blog/Gutenberg/Blocks";
import Summary from "./Summary";
import Breadcrumb, { postBreadcrumbs } from "templates/Blog/common/Breadcrumb";
import { Author, AuthorDescription } from "templates/Blog/common/Author";
import { MiaDescription } from "templates/Blog/common/MiaDescription";
import { postSlug } from "templates/Blog/utils/slugs";
import { stripHtml } from "templates/Blog/utils";
import { GatsbyImage } from "gatsby-plugin-image";
import PostThumbnailImage from 'templates/Blog/common/PostThumbnailImage';
import "./styles.sass";

class Post extends React.Component {
  render() {
    const {
      data: { post, previous, next },
    } = this.props;
    const authorName = post.author.node.name;

    let filteredBlocks = post.blocks
    const firstHeadingIndex = filteredBlocks.findIndex((it) => it.name == 'core/heading' && it.attributes.level == 2 )

    let introductionParagraphBlocks = null

    if (firstHeadingIndex != -1) {
      introductionParagraphBlocks = filteredBlocks.slice(0, firstHeadingIndex).filter(it => it.name == 'core/paragraph')
      filteredBlocks = filteredBlocks.filter((it) => !introductionParagraphBlocks.includes(it))
    }

    const questionsAndAnswers = filterQAsFromBlocks(filteredBlocks)

    return (
      <BlogLayout>
        <CharlesMeta
          title={post.seo.title}
          description={post.seo.metaDesc}
          image={
            post.socialImage.node.localFile.childImageSharp.gatsbyImageData
              .images.fallback.src
          }
        />
        <Helmet>
          <meta name="charles:external:id" content={post.id} />
        </Helmet>
        <Container className="blog-post-container">
          <Breadcrumb links={postBreadcrumbs(post)} hideLast={post.title} />
          <StructuredArticle postId={post.id} />
          <StructuredFAQ data={questionsAndAnswers} />
          <h1>{post.title}</h1>
          <Row className="blog-post-meta">
            <Col xs={6} lg={4}>
              <Author data={post.author.node} />
            </Col>
            <Col xs={6} lg={4}>
              <Author data={{ name: "Gilbert Bou Jaoudé" }} />
            </Col>
            <Col xs={12} lg={4}>
              <div className="blog-post-date">
                Mis à jour le {post.date}
              </div>
            </Col>
          </Row>
          <div className="position-relative blog-post-image">
            <PostThumbnailImage post={post} />
          </div>
          <Blocks context={post} blocks={introductionParagraphBlocks} />
          <Summary post={post} />
          <Blocks context={post} blocks={filteredBlocks} />
          <MiaDescription/>
          <ShareButtons title={post.title} uri={post.uri}/>
          <Row>
            <Col xs={12} md={6} className="text-left">
              {previous && (
                <div className="blog-post-edges-links">
                  <div className="blog-post-edges-links-prev">Article précédent</div>
                  <Link to={postSlug(previous)}>{previous.title}</Link>
                </div>
              )}
            </Col>
            <Col xs={12} md={6} className="text-right">
              {next && (
                <div className="blog-post-edges-links">
                  <div className="blog-post-edges-links-next">Article suivant</div>
                  <Link to={postSlug(next)}>{next.title}</Link>
                </div>
              )}
            </Col>
          </Row>
          <AuthorDescription name={authorName} />
          <ScrollCta />
        </Container>
      </BlogLayout>
    );
  }
}

export default Post;

function filterQAsFromBlocks(blocks) {
  let qas = []

  let tmp = { question: null, answer: null }
  let isAnswering = false
  let questionLevel

  for (let block of blocks) {
    if (block.name == 'core/heading') {
      if (isAnswering && block.attributes.level <= questionLevel) {
        qas.push(tmp)
        isAnswering = false
        tmp = { question: null, answer: null }
      }
      if (block.originalContent.match('g-faq')) {
        isAnswering = true
        questionLevel = block.attributes.level
        tmp.question = stripHtml(block.originalContent)
      }
    } else if (isAnswering && (
      block.name == 'core/paragraph' || block.name == 'core/heading')
    ) {
      if (tmp.answer)
        tmp.answer += ' '
      else
        tmp.answer = ''
      tmp.answer += stripHtml(block.originalContent)
    }
  }

  if (isAnswering == true) qas.push(tmp);

  return qas
}

export const socialImageFragment = graphql`
  fragment SocialImage on File {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800, height: 420)
    }
  }
`;

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      slug
      uri
      categories {
        nodes {
          id
          slug
          name
        }
      }
      date(formatString: "DD MMMM YYYY", locale: "FR")
      author {
        node {
          name
          firstName
          lastName
          uri
          slug
        }
      }
      featuredImage {
        node {
          ...BlogImage
        }
      }
      socialImage: featuredImage {
        node {
          localFile {
            ...SocialImage
          }
        }
      }
      blocks {
        ...GutenbergBlock
      }
      seo {
        metaDesc
        title
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
