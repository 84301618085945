import React from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import { headingToAnchor, stripHtml } from "templates/Blog/utils";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { postSlug } from "templates/Blog/utils/slugs";

import "./styles.sass";

class Summary extends React.Component {
  render() {
    const { post } = this.props;
    const headings = post.blocks
      ? post.blocks.filter(
          (block) => block.name === "core/heading" && block.attributes.level === 2
        )
      : [];

    const path = postSlug(post);

    return (
      <div>
        <div className="h2">Sommaire</div>
        <ol className="post-block-summary">
          {headings.map((heading, index) => (
            <li className="post-block-summary-item" key={`summary_heading_${index}`}>
              <AnchorLink to={`${path}#${headingToAnchor(heading)}`} className="post-block-summary-item-link">
                {stripHtml(heading.attributes.content)}
              </AnchorLink>
            </li>
          ))}
        </ol>
      </div>
    );
  }
}

export default Summary;
