import React, { Component } from "react";
import StyledLink from 'components/common/utils/StyledLink';

import './ScrollCta.sass';

class ScrollCta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCTA: false,
      prevScrollpos: 0
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { showCTA, prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    if (!showCTA && currentScrollPos > window.visualViewport.height * 2 && currentScrollPos > prevScrollpos + 10)
      this.setState({showCTA: true})
    else if (showCTA && (prevScrollpos > currentScrollPos || currentScrollPos <= window.visualViewport.height * 2))
      this.setState({showCTA: false})

    this.setState({prevScrollpos: currentScrollPos})
  };

  render() {
    return (
      <div className="scroll-cta-item fixed-bottom d-lg-none" style={{display: this.state.showCTA ? 'block' : 'none'}}>
        <p className="scroll-cta-item-title mb-2">
          Téléconsultez une sexologue
        </p>
        <StyledLink path="/consulter/" variant="orange" className="btn-block">
          Consulter
        </StyledLink>
       </div>
    )
  }
}

export default ScrollCta
